.m-menu {

  position: fixed;
  top:14px;
  right:25px;
  display: none;
  z-index: 12345678;

  &:before {
    position: absolute;
    display: block;
    content:'';
    width:50px;
    height: 3px;
    left: 0px;
    top: 24px;
    background-color: $maincolor;
    transition: all 0.15s  cubic-bezier(0.445, 0.050, 0.550, 0.950);
  }

  .mm {
    border:none;
    background:transparent;
    cursor: pointer;
    position: relative;
    display: block;
    width:50px;
    height:50px;
    outline:none;
    padding: 0; // ios has a bug otherwise

    &:before, &:after {
      position: absolute;
      display: block;
      content:'';
      width:50px;
      height: 3px;
      left: 0px;
      top: 12px;
      background-color:$maincolor;
      transition: all 0.15s  cubic-bezier(0.445, 0.050, 0.550, 0.950);
    }
    &:after {
      top: 36px;
    }

  }

}



nav {
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    li {
      margin: 5px;
      display: block;
      a, span.button {
        display: block;
      }
    }
  }
  li ul {
    display: none;
    position: absolute;
    padding-top: 5px;
    right: 0;
  }
  li:hover {
    ul {
      // display: flex;
      flex-wrap: wrap;
      margin-left: -5px;
    }
  }
  .langswitch {
    display: flex;
  }

  .p40 ul {
    display: none !important; // dirty hack: ;
  }
}

@media only screen and (max-width: 35em) {
  .m-menu {
    display: block;
  }

  .nav {
    display: none;
  }

  .m-menu-open {

    .m-menu {
      &:before {
        opacity: 0;
      }
    }
    .m-menu .mm {
      &:before {
        top:24px;
        transform:rotate(-45deg);
      }
      &:after {
        top:24px;
        transform:rotate(45deg);
      }
    }

    .nav {
      position: absolute;
      display: block;
      top: 75px;
      width: calc(100% - 10px);
      left: 5px;
      ul {
        flex-flow: column;
        // width: 100%;
        li {
          // width: 100%;
          text-align: center;
          a, .button {
            padding: 10px;
          }
          ul {
            position: relative;
            li:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .langswitch-holder {
      order:-1;
    }
  }

}

// apply hover effect only for non touch devices to prevent toggle bug on mobile
@media not all and (pointer: coarse) {
  nav li:hover {
      ul {
        display: flex;
      }
  }
}