// @font-face {
//   font-family: 'Kern';
//   src: url('/webfonts/kern_regular-webfont.woff2') format('woff2'),
//         url('/webfonts/kern_regular-webfont.woff') format('woff'),
//         url('/webfonts/Kern Regular.ttf') format('truetype'),
//         url('/webfonts/kern_regular-webfont.svg') format('svg');
//   font-weight: normal;
//   font-style: normal;
// }
@font-face {
  font-family: 'Kern';
  src: url('/webfonts/Kern-Regular-the1970s.woff2') format('woff2'),
        url('/webfonts/Kern-Regular-the1970s.woff') format('woff'),
        url('/webfonts/Kern-Regular-the1970s.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Kerni';
  src: url('/webfonts/kern_italic-webfont.woff2') format('woff2'),
        url('/webfonts/kern_italic-webfont.woff') format('woff'),
        url('/webfonts/Kern Italic.ttf') format('truetype'),
        url('/webfonts/kern_italic-webfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

html {
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: Kern, Helvetica Neue, sans-serif;
  font-size: 1.825rem;
  line-height: 2.15rem;
  font-weight: normal;
}

.small {
  font-size: 1.1rem;
  line-height: 1.2rem;
}
.medium {
  font-size: 1.325rem;
  line-height: 1.575rem;
}

h1, h2, h3, h4 {
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-size: 1.825rem;
  line-height: 2.15rem;
}
h3 {
  text-transform: uppercase;
}

nav, .langswitch {
  text-transform: lowercase;
}

.events .item {
  font-size: 1.5rem;
  line-height: 1.8rem;
}
.event-type {
 line-height: 1.9rem;
}