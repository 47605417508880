/* Put your global styles here */
@import 'normalize';
@import 'boilerplate';
@import 'variables';
@import 'typo';
@import 'layout';
@import 'nav';

[v-cloak]>* {
  display: none
}

@keyframes rotatingImage {
  0% {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }

  25% {
    -webkit-transform: rotateY(-360deg);
    transform: rotateY(-360deg);
  }

  75% {
    -webkit-transform: rotateY(-360deg);
    transform: rotateY(-360deg);
  }

  100% {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
}


body {
  background-color: $bgcolor;
  color: $maincolor;
}

a {
  color: $maincolor;

  &:hover {
    text-shadow: 0 0 0.3em rgba(0, 0, 0, 0.5);
  }
}

.content a {
  text-shadow: 0 0 0.3em rgba(0, 0, 0, 0.5);
  text-decoration: none;
}

.link-panels {
  a {
    text-shadow: none;

    &:hover {
      text-shadow: 0 0 0.3em rgba(0, 0, 0, 0.5);
    }
  }

}

.active,
a:hover {
  //color: $highlightcolor;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

ul,
li {
  margin: 0;
  padding: 0;
}

nav li,
.langswitch li,
footer li {
  list-style: none;
}


.button,
nav a,
.langswitch {
  background-color: $white;
  box-shadow: 0 0 0.7em rgba(0, 0, 0, 0.25);
  color: $maincolor;
  border-radius: 25px;
  padding: 0 15px 2px 15px;
  text-decoration: none;

  &:hover {
    color: $maincolor;
    text-shadow: 0 0 0.3em rgba(0, 0, 0, 0.5);
  }
}

.langswitch {
  padding: 0 10px 2px 10px;
}

.langswitch a {
  background: none;
  box-shadow: none;
  text-decoration: none;
  color: $white;
  text-shadow: 0 0 0.3em rgba(0, 0, 0, 0.5);
  margin: 0;
  padding: 0 5px;

  &.active,
  &:hover {
    color: $maincolor;
  }
}

.button.active,
nav li.active a {
  background-color: $maincolor;
  color: $white;

  &:hover {
    text-shadow: 0 0 0.3em rgba(255, 255, 255, 1);
  }
}


main.main // , header.header

  {
  background-color: $white;
}

.item {

  padding: 10px;

  &.big {
    background-color: #ebebeb;
  }

  a {
    text-decoration: none;
  }
}

.publics {
  perspective: 1000px;

  /* Remove this if you don't want the 3D effect */
  a {
    color: white;
    display: block;
  }

  img {
    margin: 0 auto;
    max-width: 75%;
    border-radius: 25px;
    animation: 60s rotatingImage linear infinite;
  }

}

.jobs {
  max-width: 75%;
  margin: 0 auto 40px auto;

  a {
    color: white;
    text-decoration: none;
    border-radius: 25px;
    background-color: $blue;
    text-align: center;
    display: block;
    padding: 90px;
    word-break: break-word;
  }

}

.location:not(.empty) {
  &:before {
    content: '@';
  }
}

.opening {
  background-color: $blue;
  color: $white;
  border-radius: 25px;
  padding: 7px 7px;

  span {
    border: 1px solid $white;
    padding: 0 2px;
    margin-right: 3px;
  }
}

.search-input {
  border-radius: 25px;
  border: none;
  box-shadow: 0 0 0.7em rgba(0, 0, 0, 0.15);
  color: $maincolor;
  padding: 3px 7px;
  width: calc(100% - 14px);
  outline: none;
}

::-webkit-input-placeholder {
  /* Edge */
  color: $maincolor;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $maincolor;
}

::placeholder {
  color: $maincolor;
}

.panel,
.small-panel {
  border-radius: 25px;
  padding: 10px;

  &.blue {
    background-color: $blue;
    color: $white;

    a {
      color: $white;
    }
  }

  &.shadow {
    box-shadow: 0 0 0.7em rgba(0, 0, 0, 0.2);
    border-radius: 25px;
  }
}

.body-txt,
.column {
  ul {
    margin-left: 10%;
    max-width: 80%;
  }

  li {
    list-style: none;
    position: relative;
    padding-left: 20px;
    margin-left: 10px;

    &:before {
      content: '•';
      position: absolute;
      top: 0;
      left: -10px;
    }
  }
}


// HOMEPAGE
.programme .events-wrapper {
  position: relative;

  .events:after {
    width: 100%;
    height: 50%;
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    pointer-events: none;
  }

  a {
    text-decoration: none;
  }

}

.home,
.programme-page {
  .event-type {
    text-transform: uppercase;
  }
}

.single-events {
  h4 {
    text-transform: uppercase;
  }
}

.home .media-library {
  // background-color: $maincolor;
  color: $white;
  // border-radius: 15px;
  min-height: 100px;
}

.home .media-library {
  background-color: transparent;
  width: 100%;
  height: 250px;
  perspective: 1000px;

  /* Remove this if you don't want the 3D effect */
  // overflow-x: hidden;
  &:hover .media-library-inner {
    transform: rotateY(180deg);
  }

  .item {
    backface-visibility: hidden; // chrome fix
  }


}

/* This container is needed to position the front and back side */
.media-library-inner {
  position: relative;
  width: 100%;
  height: 100%;
  // text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;

}

.media-library-front,
.media-library-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden; // safari fix
}

.media-library-front,
.media-library-back {
  background-color: $maincolor;
  border-radius: 25px;
}

.media-library-back {
  transform: rotateY(180deg);

  p {
    padding: 0 20px;
  }
}

.news a {
  text-decoration: none;
}


// PROGRAMME
.filter {
  background-color: #dfdfdf;
  cursor: pointer;
  color: white;
  text-transform: lowercase;
  padding: 2px 10px;
  box-shadow: 0 0 0.7em rgba(0, 0, 0, 0.15) !important;
  text-decoration: none;

  &:hover,
  &.active {
    background-color: $maincolor;
  }

}

.programme-list a {
  text-decoration: none;
}

.no-events {
  padding-bottom: 40px;
  text-align: center;
  width: 100%;
}


// FOOTER
footer li a {
  text-decoration: none;
}

.signup {
  background-color: $white;
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 25px !important;

  input {
    background: transparent;
    border: none;
    box-shadow: none;
    outline: none;
  }

  [type="email"] {
    flex-grow: 2;
    margin-left: 10px;
  }
}

// checkboxes
input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

[type="checkbox"]+span {
  position: relative;
  padding-left: 28px;
  padding-right: 24px;
  cursor: pointer;
  display: block;
  padding-top: 2px;
  line-height: 1.35;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:before {
    content: '';
    position: absolute;
    top: 10px;
    left: 0;
    width: 20px;
    height: 20px;
    z-index: 0;
    border: 1px solid white;
    border-radius: 50%;
    background: white;
    border-radius: 50%;
    box-shadow: 0 0 0.7em rgba(0, 0, 0, 0.5);
  }
}

[type="checkbox"]:checked+span {
  &:before {
    background: white;
  }

  &:after {
    content: '';
    position: absolute;
    top: 16px;
    left: 6px;
    width: 10px;
    height: 10px;
    z-index: 0;
    background-color: $maincolor;
    border-radius: 50%;
  }

}

// end checkboxes

.dissapear-enter-active,
.dissapear-leave-active {
  transition: all 0.25s;
}

.dissapear-enter,
.dissapear-leave-to {
  opacity: 0;
  // transform:scale(0) rotateY(180deg);
  transform: scale(0);

}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.25s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
}

.fancybox-bg {
  background: #fff;
}

.fancybox-button {
  background: transparent;
  color: #000;

  &:hover {
    color: #000;
    -webkit-filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, .5));
    filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, .5));
  }
}

// .page-artist-overview {
.alphabet {
  background: white;
  border-radius: 25px;

  .letter {
    background: $grey;
    border-radius: 15px;
    color: $white;
    border: none;
    margin: 10px;
    min-width: 50px;

    &:hover,
    &.active {
      background-color: $maincolor;
      color: $white;
    }
  }
}

// }

@media only screen and (max-width: 85em) {
  .jobs a {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (max-width: 65em) {
  .jobs a {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 35em) {
  header.header .bg {
    position: fixed;
    top: 0;
    z-index: 1234;
    background-color: $white;
    height: 60px;
    width: calc(100% - 20px);
    margin: 10px;
    box-shadow: 0 0 0.7em rgba(0, 0, 0, 0.25);
    border-radius: 25px;
  }

  .logo {
    z-index: 1234567;
    max-width: 200px;
    top: 20px;
    left: 20px;
  }

  .home .logo {
    display: block;
  }

  // home nav animation
  .home {

    .logo,
    header.header .bg,
    .m-menu {
      top: -100px;
      transition: all 150ms cubic-bezier(.92, .06, .12, .73);
    }
  }

  .home.scrolling {
    header.header .bg {
      top: 0;
    }

    .logo {
      top: 20px;
    }

    .m-menu {
      top: 14px;
    }
  }

  .body-txt,
  .column {
    ul {
      margin-left: 0;
      max-width: 100%;
    }
  }
}