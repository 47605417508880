/* MAIN ELEMENTS */

main.main,
header.header {
  z-index: 1;
}

main.main {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: nowrap;
}

.logo {
  position: fixed;
  max-width: 300px;
  top: 10px;
  left: 10px;
  z-index: 1234;
}

nav {
  position: fixed;
  top: 5px;
  right: 5px;
  z-index: 1;
  z-index: 1235;
}

.home nav {
  width: calc(100% - 5px);
}

.langswitch {
  display: flex;
  justify-content: center;
}

.signup {
  margin-bottom: 140px;

  #mc_embed_signup_scroll,
  form {
    width: 100%;
  }

  .mc-field-group {
    display: flex;

  }

  .input-group {
    // display: none;
    flex-wrap: wrap;
  }

  // &:hover {
  //   .input-group {
  //     display: flex;
  //   }
  // }
  .nl-label {
    margin-right: 20px;
    padding-top: 4px;
  }
}

footer.footer {
  position: fixed;
  bottom: 40px;
  z-index: -1;

  ul {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    li {
      margin: 5px 10px;
    }
  }
}

/* PIECES */

.item.in,
.item.out {

  .info {
    display: flex;
    justify-content: space-between;

    .location {
      text-align: right;
    }
  }

  .img-holder {
    position: relative;
  }

  .info-right {
    text-align: right;
  }

  .svg-holder {
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  svg {
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    margin-left: -1px;
  }

  img {
    width: 100%;
  }
}

// new style images
.item.in,
.item.out {
  align-self: flex-start;
  // width: 50%;

  &.item-v2 {
    margin-top: 30px !important;
  }

  .img-holder-v2 {
    display: flex;
    overflow: hidden;
    position: relative;
    margin: 20px;
  }

  .img-holder-v2:hover .img-v2 {
    clip-path: circle(100%);
  }

  .img-v2 {
    height: 100%;
    clip-path: url(#cp);
    // max-height: 500px; // don't max height
    width: 100%; // stretch for small images
  }

  // no animation for the main image
  &.static:hover .img-holder-v2:hover .img-v2 {
    clip-path: url(#cp);
  }
}

.page-artist,
.page-event,
.page-work {
  .img-v2 {
    max-height: none !important;
    width: 100%;
  }
}

// end new style images


// HOMEPAGE

.home {
  .logo {
    display: none;
  }
}

.left,
.right {
  width: calc(50% - 20px);
  padding: 10px;
}

.right {
  margin-top: 60px;
}

.search-input {
  margin: 10px 0 20px 0;
}

.big,
.opening {
  margin-bottom: 20px;
}

.big {
  .info {
    margin-bottom: 10px;
  }

  .event-type {
    margin-bottom: 10px;
  }

}

.publics {
  margin: 50px 0;

  position: relative;

  h2 {
    position: absolute;
    top: 45%;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    z-index: 12345;
    padding-left: 60px;
    padding-right: 60px;
  }
}

.programme {
  margin-bottom: 20px;

  h2 {
    margin-bottom: 10px;
  }

  .events-wrapper {
    width: 100%;
    height: 210px;
    overflow: hidden;
  }

  .events {
    padding-top: 10px;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding-right: 17px;
    box-sizing: content-box;
  }

  .event {
    display: flex;
    flex-direction: column;
    border-top: 2px solid $maincolor;
    margin-bottom: 5px;
    padding: 10px 0;

    .practical {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      &:last-child {
        border-bottom: none;
      }

      margin-bottom: 20px;
    }

    &.t-exhibition .practical,
    &.t-tentoonstelling .practical,
    &.t-exposition .practical {
      .time {
        display: none;
      }
    }

    .title {
      margin-bottom: 10px;
    }


  }

}

.single-events {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .panel {
    max-width: calc(50% - 30px);
    margin-bottom: 20px;
    align-self: flex-start;
  }

  h4 {
    margin: 10px 0;
  }
}

.media-library a {
  color: $white;
  text-decoration: none;
  display: block;

  &:hover {
    color: $white;
  }

  .media-library-front {
    display: flex;
    justify-content: space-between;

    h2 {
      width: 50%;
    }
  }

  h2 {
    margin-top: 20px;
    margin-left: 20px;
  }

  .item {
    max-width: 400px;
    width: 50%;
  }
}

.news {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: $green;
  width: 100%;
  height: 40px;
  z-index: 12345;

  .label {
    background-color: $maincolor;
    color: white;
    padding: 2px 5px 4px 5px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 123;
  }

  .messages {
    height: 40px;
    padding-top: 4px;

    a {
      margin-right: 50px // display: inline-block;
    }
  }

}


// REGULAR PAGE

.page .content,
.page-event .content,
.page-artist .content,
.page-work .content {
  padding: 100px 15px 10px 15px;
  width: calc(100% - 30px);

  .panel {
    position: relative;
    margin-bottom: 20px;
  }

  h1 {
    margin: 20px 20px 0 20px;
    position: absolute;
    top: 0;
    z-index: 123;

    &.no-img {
      position: relative;
      margin: 20px 0 20px 0;
      text-transform: uppercase;
    }
  }

  h3 {
    margin-top: 20px;
  }

  .item {
    margin-top: 10px;
  }

  p.first {
    margin-top: 5px;
  }

}

.small-panel {
  display: inline-block;
  vertical-align: top;
  width: calc(50% - 45px);
  padding: 0 5px;
  margin: 10px 10px 20px 10px;

  .panel-upper {
    p {
      padding: 0;
      margin: 0;
    }
  }
}

.table {
  display: flex;
  flex-direction: row;
  width: 100%;

  .length,
  .price {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border-right: 2px solid $maincolor;

    &.price {
      border-right: none;
    }

    >div {
      max-width: 200px;
      padding: 10px;
      width: calc(100% - 20px);
      border-top: 2px solid $maincolor;

      &.label {
        width: calc(100% - 20px) !important;
        border-top: none;
      }
    }
  }

}

.column {
  display: inline-block;
  vertical-align: top;
  max-width: calc(50% - 24px);
  width: calc(50% - 34px);
  // margin: 20px 0;
}

iframe,
#map,
.ol-viewport {
  border: none;
  width: 100%;
  // height: 700px;
  border-radius: 25px;
  margin-top: 50px;
}
.body-txt iframe {
  // border-radius: 0px;
}
.fluid-width-video-wrapper {
  margin-bottom: 150px;
}

.link-panels {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  .item {
    width: calc(100%/3 - 40px);
    margin: 10px;
  }
}

.gallery {
  display: flex;
  flex-wrap: wrap;

  .item {
    width: calc(25% - 20px);
  }

  .svg-holder {
    top: -1px;
  }
}

// PROGRAMME PAGE
.programme-page,
.page-work-overview {
  main.main {
    flex-direction: column;
  }
}

.filters {
  display: flex;
  margin-top: 110px;
  margin-bottom: 30px;
  justify-content: center;
  flex-wrap: wrap;

  .filter {
    margin: 5px;
  }

  .has-sub {
    position: relative;
    display: flex;
    flex-direction: column;

    ul {
      position: absolute;
      padding: 50px 0 0 0;
      margin: 0;
      display: none;
    }

    li {
      list-style: none;
      margin: 0 20px 10px -15px;
    }

    &:hover {
      ul {
        display: flex;
      }
    }
  }


}

.programme-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px;

  .item {
    max-width: calc(50% - 30px);
    width: calc(50% - 30px);
    margin-bottom: 20px;

    .info {
      margin-bottom: 10px;
    }

    .event-type {
      margin-bottom: 10px;
    }
  }
}

.page-event {
  .event-info {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

  }

  .practical {}
}

.page-search {
  .content {
    padding-bottom: 20px;

    h1 {
      position: relative;
      margin: 20px 0;
    }

    ul {
      margin-bottom: 40px;
    }
  }

}

.page-artist-overview,
.page-work-overview {
  .programme-list {
    margin-top: 100px;
  }

  .alphabet-filter {
    display: none;
  }

  .alphabet {
    position: fixed;
    width: calc(100% - 60px);
    padding: 20px;
    left: 0;
    right: 0;
    bottom: 20px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    z-index: 1234;

    .number {
      margin-left: auto !important;
    }
  }
}

// artist page
.birthyear {
  position: relative;
  padding-left: 15px;

  &:before {
    position: absolute;
    content: '°';
    top: 0;
    left: 0;
  }
}

// work page
.page-work {
  .programme-list {
    margin-top: 20px;
  }
  .technical {
    display: flex;
    justify-content: space-between;
  }
}


// pager
.pager {
  display: flex;
  justify-content: space-between;
  padding: 20px 40px;
  margin-bottom: 20px;

  a {
    text-decoration: none;
  }

  .inactive {
    opacity: 0.1;
  }
}



@media only screen and (max-width: 70em) {
  .single-events {
    flex-wrap: wrap;

    .panel {
      max-width: calc(100% - 20px);
      margin-bottom: 20px;
    }
  }

  .link-panels {
    .item {
      width: calc(100%/2 - 40px);
    }
  }

  .column {
    display: block;
    max-width: calc(100%);
    width: calc(100%);
  }

}

@media only screen and (max-width: 45em) {

  .home {
    main.main {
      flex-wrap: wrap;
    }

    .left,
    .right {
      width: calc(100% - 20px);
    }

    .right {
      margin-top: 0;
    }
  }


  .signup {
    margin-bottom: 180px;

    .nil {
      display: none;
    }

    [type="email"] {
      flex-grow: 1 !important;
      width: 100%;
    }
  }

  .programme-list .item {
    max-width: 100%;
    width: 100%;
  }

  .programme {
    .event-type {
      // margin-left: auto;
      // margin-left: 0 !important;
    }
  }

  .small-panel {
    display: block;
    width: calc(100% - 20px);
    margin: 20px 0;
  }

  .link-panels {
    .item {
      width: calc(100% - 40px);
    }
  }

  .page .content,
  .page-event .content {
    h1 {
      margin: 10px 20px 0 20px;
      position: relative;
    }

    .item {
      margin-top: -10px;
    }
  }


  .table {
    flex-direction: row;
    width: 100%;

    .length,
    .price {
      justify-content: space-between;
      flex-direction: column;
      border-bottom: none;
      border-right: 2px solid $maincolor;

      // width: 50%;
      &.price {
        border-right: none;
      }

      >div {
        &.label {
          width: calc(100% - 20px) !important;

          border-top: none;
        }

        width: calc(100% - 20px);
        border-left:none;
        border-top: 2px solid $maincolor;

        &:first-child {
          border-left: none;
        }
      }
    }
  }

  .gallery .item {
    width: calc(50% - 20px);
  }

  .page-artist-overview,
  .page-work-overview {
    .alphabet {
      transform:translateY(100%);
      &.filter-active {
        transform:translateY(0);
      }
    }
    .alphabet-filter {
      display: block;
      position: fixed;
      bottom: 40px;
      left: 20px;
      border-radius:25px;
      padding: 0 15px 2px 15px;
      background:$maincolor;
      color: white;
      cursor: pointer;
      &.filter-active {
        transform:translateY(0);
      }
    }
  }

}

@media only screen and (max-width: 35em) {
  .gallery .item {
    width: calc(100% - 20px);
  }
}

@media only screen and (max-width: 25em) {
  .signup {
    margin-bottom: 220px;
  }

  .gallery .item {
    width: calc(100% - 20px);
  }
}